<template>
  <div class="coverflow" v-coverflow>
    <img
      alt="Image"
      v-for="(coverItem, index) of coverList"
      :src="coverItem.cover"
      :key="index"
      v-bind:link="coverItem.package"
      v-on:click="redirectLink(coverItem.package)"
    />
  </div>
</template>

<script>
import coverflow from './directives/coverflow';
export default {
  name: 'coverflow',
  data() {
    return {
      coverIndex: 0,
      click: 0,
    };
  },
  props: {
    coverList: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 1140,
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
    index: {
      type: Number,
      default: 0,
    },
    coverWidth: {
      type: Number,
      default: 100,
    },
    coverHeight: {
      type: Number,
      default: 0,
    },
    coverSpace: {
      type: Number,
      default: 50,
    },
    coverShadow: {
      type: Boolean,
      default: false,
    },
    coverFlat: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(index) {
      this.coverIndex = index;
      this.$emit('change', index);
    },
    redirectLink: function (value) {
      var s4link = localStorage.getItem('s4link');
      if (s4link !== value) {
        localStorage.setItem('s4link', value);
      }
      var packagelink = value;
      if (this.click == 0) {
        this.click = 1;
      } else if (this.click == 1) {
        if (s4link === value) {
          var baseUrl = window.location.origin + '/#/tour/' + value + '.html';
          setTimeout(function () {
            window.location.assign(baseUrl);
          }, 1200);
        }
        this.click = 0;
      }
    },
  },
  directives: {
    coverflow,
  },
};
</script>
<style type="text/css">
img::before {
  content: '';
  border: 1px solid #f30;
}
</style>
