import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const state = {
  menus: null,
  site: null,
  telephone: null,
  logo_link: null,
};

const getters = {
  getMenus: (state) => state.menus,
  getSite: (state) => state.site,
  getTelephone: (state) => state.telephone,
  getLogo: (state) => state.logo_link,
};

const mutations = {
  updateMenus(state, payload) {
    state.menus = payload;
  },
  updateSite(state, payload) {
    state.site = payload;
  },
  updateTelephone(state, payload) {
    state.telephone = payload;
  },
  updateLogo(state, payload) {
    state.logo_link = payload;
  },
};

const actions = {
  getAllSite({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.post('/fuson-param/client/get/base/config')
        .then((res) => {
          commit('updateSite', res.data.returnData);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchMenus({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/menus')
        .then((res) => {
          commit('updateMenus', res.data.data);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchTelephone({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/phone')
        .then((res) => {
          commit('updateTelephone', res.data.data.phoneNumber);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchLogo({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/logo')
        .then((res) => {
          commit('updateLogo', res.data.data.url);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
