import Vue from 'vue';
import Router from 'vue-router';
import package_list from './views/package/package_list.vue';
import MyOrder from './views/my_order.vue';
import OrderDetail from './views/order_detail.vue';
import EditOrder from './views/edit_order.vue';
import store from './store/store.js';
import ApiService from './service/ApiService.js';
import appLoading from "./utils/appLoading.js"

Vue.use(Router);
var baseUrl = window.location.origin;

const ifAuthenticated = (to, from, next) => {
  if (store.getters['authModule/getAuthentication']) {
    const exp = localStorage.getItem('exp');
    var today = new Date();
    var unixTimeStamp = Math.floor(today.getTime() / 1000);
    if (exp && exp < unixTimeStamp) {
      store.dispatch('authModule/logout');
      store.dispatch('paymentModule/clear');
      return;
    }
    store.dispatch('authModule/keeplogin');
    next();
    return;
  }
  const currentUrl = baseUrl + '/#' + to.path;
  sessionStorage.setItem('before_url', currentUrl);
  window.location.assign(baseUrl + '/?login=1');
};

const router = new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  // mode: 'history',
  // hash: false,
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: () => import('./Layout/MainLayout.vue'),
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('./views/home/home.vue'),
        },
        {
          path: '/page/:pagecode',
          name: 'page',
          component: () => import('./views/page.vue'),
        },
        {
          path: '/packages/:area',
          name: 'PackagesList',
          component: package_list,
          // beforeEnter: ifAuthenticated
        },
        {
          path: '/search/:query_search',
          name: 'SearchPage',
          component: () => import('./views/search.vue'),
          // beforeEnter: ifAuthenticated
        },
        {
          path: '/tour/:package_code',
          name: 'PackageDetail',
          component: () => import('./views/package/package_detail.vue'),
          // beforeEnter: ifAuthenticated
        },
        {
          path: '/booking',
          name: 'Booking',
          component: () => import('./views/booking.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/payment',
          name: 'Payment',
          component: () => import('./views/payment.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/success/:status',
          name: 'Success',
          component: () => import('./views/success.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/my-order',
          name: 'MyOrder',
          component: MyOrder,
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/order-detail/:order_id',
          name: 'OrderDetail',
          component: OrderDetail,
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/edit-order/:order_id',
          name: 'EditOrder',
          component: EditOrder,
          beforeEnter: ifAuthenticated,
        },
      ],
    },
    {
      path:"/invoices/:id",
      component:()=>import("./views/invoices/index.vue")
    },
    {
      path: '/under_contruction',
      name: 'UnderContruction',
      component: () => import('./views/under_contruction.vue'),
    },
    {
      path: '/transition',
      name: 'transition',
      component: () => import('./views/transitionPage.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(async(to, from, next) => {
  try{
    await appLoading()
    next();
  }catch(e){
    console.log(e)
    next('/')
  }
});
router.afterEach(() => {
  // setTimeout(() => NProgress.done(), 500);
});

export default router;
