import ApiService from '../../service/ApiService';
import configResponse from '../../service/configResponse';

const actions = {
  fetchBanners({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('/banners')
        .then((res) => {
          commit('updateBanners', res.data.data);
          resolve();
        })
        .catch(({ response }) => {
          configResponse(response);
          reject();
        });
    });
  },
  fetchSectionByNumber({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/fuson-param/client/section/query",data)
        .then((res) => {
          resolve(res.data.returnData);
        })
        .catch(({ response }) => {
          configResponse(response);
          reject(response.data);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
