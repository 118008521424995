import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import authModule from './modules/authModule';
import headerModule from './modules/headerModule';
import footerModule from './modules/footerModule';
import homeModule from './modules/homeModule';
import tourModule from './modules/tourModule';
import orderModule from './modules/orderModule';
import pageModule from './modules/pageModule';
import paymentModule from './modules/paymentModule';
import parametersModule from './modules/parametersModule';

export const SET_AUTH = 'set_auth';
export const CLEAR_AUTH = 'clear_auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: null,
  },
  getters: {
    getLanguage: (state) => state.language,
  },
  mutations,
  actions,
  modules: {
    authModule,
    headerModule,
    footerModule,
    homeModule,
    tourModule,
    orderModule,
    pageModule,
    paymentModule,
    parametersModule,
  },
  plugins: [createPersistedState({
    key:'fs-web'
  })],
});
