import axios from 'axios';
import JwtService from './jwtService';
const isWhitePath = (path, whitePath) => {
  const lastRoute = path.split('/').pop();
  return !whitePath.includes(lastRoute);
};
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description initialize vue axios
   */
  static init() {
    const whitePath = ['active', 'login'];
    axios.defaults.baseURL = `/gateway`;
    axios.interceptors.request.use((config) => {
      config.headers = {
        org: localStorage.getItem('areaOrg')?localStorage.getItem('areaOrg'):'HKG',
        // Authorization: "Bearer "+localStorage.getItem('token')
        language: localStorage.getItem('lang_frontend')?localStorage.getItem('lang_frontend'):'hk',
      };

      if (isWhitePath(config.url, whitePath)) {
        config.headers['Authorization'] = localStorage.getItem('token')
          ? 'Bearer ' + localStorage.getItem('token')
          : '';
      }
      return config;
    });
    axios.interceptors.response.use((response) => {
      let data = response.data;
      if (data.returnCode === '999999') return Promise.reject({ response });
      return response;
      // if(response.)
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static query(resource, params) {
    return axios.get(resource, params);
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  static get(resource, slug = '') {
    return axios.get(`${resource}`);
  }
  static gets(resource) {
    return axios.get(`${resource}`);
  }
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static post(resource, params) {
    return axios.post(`${resource}`, params);
  }
  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  }

  static patch(resource, slug, params) {
    return axios.patch(`${resource}/${slug}`, params);
  }
  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static put(resource, params) {
    return axios.put(`${resource}`, params);
  }
  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static delete(resource, params) {
    return axios.delete(`${resource}`, { data: params });
  }
}
export default ApiService;
