import { SECRET_KEY } from '../store/types';

export const enCryptDataLogin = (value) => {
  const SECRETKEY_EN = Buffer.from(SECRET_KEY, 'utf-8');
  // Convert the string value to a Buffer
  const valueBuffer = Buffer.from(value, 'utf-8');

  // Determine the key length
  const keyLength = SECRETKEY_EN.length;

  // Initialize a Buffer to store the encrypted values
  const encryptedBuffer = Buffer.alloc(valueBuffer.length);

  // XOR operation to encrypt each byte of the value with the key
  for (let i = 0; i < valueBuffer.length; i++) {
    encryptedBuffer[i] = valueBuffer[i] ^ SECRETKEY_EN[i % keyLength];
  }

  // Convert the encrypted Buffer to base64
  const encryptedValue = encryptedBuffer.toString('base64');

  return encryptedValue;
};
