import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import BootstrapVue from 'bootstrap-vue';
import VueStickyKit from 'vue-stickykit';
import coverflow from '../lib';
import VueResource from 'vue-resource';
import Notifications from 'vue-notification';
import VueToastify from 'vue-toastify';
import Carousel3d from 'vue-carousel-3d';
import Vuelidate from 'vuelidate';
import VeeValidate from 'vee-validate';
import vueDebounce from 'vue-debounce';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import langCn from '@/translation/cn.json';
import langEn from '@/translation/en.json';
import langHk from '@/translation/hk.json';

import LoadScript from 'vue-plugin-load-script';
import VueQRCodeComponent from 'vue-qrcode-component';
import i18n from '@/translation/i18n';

import moment from 'moment';

// If you don't need the styles, do not connect
import VueShave from 'vue-shave';
import ApiService from './service/ApiService';

import VueCardFormat from 'vue-credit-card-validation';

Vue.component('qr-code', VueQRCodeComponent);
Vue.use(LoadScript);
Vue.use(VueToastify);
Vue.use(BootstrapVue);
Vue.use(VueStickyKit);
Vue.use(coverflow);
Vue.use(VueResource);
Vue.use(Notifications);
Vue.use(VueShave);
Vue.use(VueCardFormat);
Vue.use(Vuelidate);
Vue.use(Carousel3d);
Vue.use(vueDebounce);

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors',
});
ApiService.init();

const getLang = localStorage.getItem('lang_frontend');
if (getLang == 'cn') {
  Vue.prototype.$language = langCn;
} else if (getLang == 'hk') {
  Vue.prototype.$language = langHk;
} else {
  Vue.prototype.$language = langEn;
}

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

Vue.config.silent = true;

Vue.http.options.credentials = true;

Vue.http.options.xhr = {
  withCredentials: true,
};

Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.http.options.crossOrigin = true;

Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.http.headers.common['Accept'] = 'application/json, text/plain, */*';
Vue.http.headers.common['Access-Control-Allow-Headers'] =
  'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin';

import '../node_modules/nprogress/nprogress.css';

Vue.component('default-layout', () =>
  import('./Layout/wrappers/BaseLayout.vue'),
);

const app = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});

export default app;
