import * as types from './types';
import app from '../main';

export default {
  [types.SET_LANG]: (state, payload) => {
    app.$i18n.locale = payload;
    if (payload == 'en') {
      state.language = 'en';
    } else if (payload == 'cn') {
      state.language = 'zh';
    } else {
      state.language = 'zh-HK';
    }
    localStorage.setItem('lang_frontend', payload);
  },
};
