<template>
  <section id="tour-detail" class="secction-h tour-section-package mt-5">
    <b-container class="bv-section1-row">
      <b-row class="text-center">
        <h3 class="w-100 p-3">
          {{ title }}
        </h3>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="12"
          v-for="(value, key, index) in info"
          :key="`${key}-${index}`"
        >
          <b-card
            class="card-image mb-3"
            :style="{
              backgroundImage: `url(&quot;${oss+value.mainPicture}&quot;)`,
            }"
            style="background-image: url(/img/s2_b1.png)"
          >
            <div class="text-white rgba-black-strong py-1 px-3 py-2">
              <div>
                <b-row>
                  <b-col cols="8" class="text-left b-item">
                    <div class="b-title">
                      <h5 class="py-2">
                        ({{ value.packageCode }}) {{ value.title }}
                      </h5>
                      <h6 class="purple-text">
                        {{ value.shortDescription }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col cols="4" class="text-right align-bottom pb-1 b-item">
                    <a :href="`#/tour/${value.packageCode}`"
                      ><b-button squared variant="outline-secondary">{{
                        $t('View_details')
                      }}</b-button></a
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import configUrl from '@/global/config.js'
export default {
  name: 'PackageList',
  data() {
    return {
      info: null,
      loading: true,
      title: '',
      errored: false,
      infolength: null,
      slug: null,
       oss:''
    };
  },
  mounted() {
    this.oss=configUrl.oss_path
    var param = this.$route.params.area;
    param =Number(param.replace('section', ''));
    this.$store
      .dispatch('tourModule/fetchTourDataBySection',  
       {condition:{sectionNumber: [param], searchMore: "Y"},
        pageSize: 10,
        pageNum: 1,})
      .then(() => {
        const data = this.$store.getters['tourModule/getSectionData'];
        this.info = data[0].packagesVos;
        this.title = data[0].title;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
<style type="text/css">
.tour-section-package {
  margin-top: 5rem !important;
}
.align-bottom {
  align-self: flex-end;
}
.tour-section-package .card {
  background-repeat: no-repeat;
  min-height: 28rem;
  background-size: cover;
  border-radius: unset;
  flex-direction: column-reverse;
}
.tour-section-package.card h5,
.tour-section-package .card h6 {
  font-weight: 400;
}
.secction-h button {
  background-color: #f6a604;
  padding: 0.3rem 1rem;
  border-radius: 4px !important;
  border: 1px solid #f6a604;
}
.area-sub-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  color: #666666;
  opacity: 1;
  margin-left: 0.5rem;
}
.b-title {
  color: white;
  text-shadow: -1px -1px 0 #ff7e09, 1px -1px 0 #ff7e09, -1px 1px 0 #ff7e09,
    1px 1px 0 #ff7e09;
}

#tour-detail .card-body {
  display: contents;
}
</style>
