<template>
  <div>
    <CarouselPagePackage />
    <SearchBar />
    <PackagesList />
    <RegisterAndLogin />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faStar,
  faQuoteRight,
  faArrowRight,
  faExternalLinkAlt,
  faDownload,
  faBook,
  faLock,
  faShapes,
  faAlignCenter,
  faTable,
  faMapMarkedAlt,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
  faDribbble,
  faGithubSquare,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
  faQuestionCircle,
  faStar,
  faQuoteRight,
  faArrowRight,
  faFacebookSquare,
  faTwitterSquare,
  faDribbble,
  faGithubSquare,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faExternalLinkAlt,
  faDownload,
  faBook,
  faLock,
  faFacebook,
  faShapes,
  faAlignCenter,
  faTable,
  faMapMarkedAlt,
  faSitemap,
);

import CarouselPagePackage from '../../Layout/home/carousel';
import SearchBar from '../../Layout/home/search_bar';
import PackagesList from '../../Layout/package/package_list';
import RegisterAndLogin from '../../Layout/home/register_and_login';

export default {
  name: 'PackageDetail',
  components: {
    CarouselPagePackage,
    SearchBar,
    PackagesList,
    RegisterAndLogin,
  },
};
</script>
