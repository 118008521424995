<template>
  <div v-if="info && info.length > 0">
    <b-carousel
      id="carousel-1"
      class="carousel-general"
      v-model="slide"
      :interval="9000"
      background="#ababab"
      img-width="1920"
      img-height="400"
      style="text-shadow: 1px 1px 2px #333"
      controls
      indicators
    >
      <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      <b-carousel-slide
        v-for="(value, key, index) in info"
        :key="`${key}-${index}`"
        class="hk-slide-img"
        img-alt="Blank image"
        :img-src="oss + value.imageUrl"
      >
        <fieldset
          class="carousel-content"
          style="width: 40rem"
          v-if="value.title !== ''"
        >
          <h3>{{ value.title }}</h3>
        </fieldset>
        <p class="carousel-subcontent" v-html="value.description"></p>
        <a
          class="button--see-more"
          v-if="value.link == 'Y'"
          :href="`${value.bannerUrl}`"
          ><b-button class="slide-button" squared variant="outline-secondary">{{
            $t('bannerText')
          }}</b-button></a
        >
      </b-carousel-slide>
    </b-carousel>
  </div>
  <div v-else style="height: 400px">
    <b-col>
      <b-skeleton-img height="400px"></b-skeleton-img>
    </b-col>
  </div>
</template>
<script>
import configUrl from '@/global/config.js';
export default {
  name: 'Slide',
  components: {},
  data() {
    return {
      info: null,
      loading: true,
      errored: false,
      slug: null,
      oss: '',
    };
  },
  mounted() {
    this.oss = configUrl.oss_path;
    // setTimeout(() => {
    const site = JSON.parse(localStorage.getItem('site'));
    this.info = site.banners || [];
    this.info = this.info.filter((e) => e.imageUrl != '');
  },
};
</script>
<style type="text/css">
.carousel-general .carousel-content {
  margin: auto;
  padding: 2rem 1rem;
  line-height: 3px;
  background-color: rgb(0 0 0 / 72%);
  opacity: 0.9;
}

.slide-button {
  background-color: rgb(0 0 0 / 72%);
  opacity: 0.9;
}

.carousel-general .carousel-content h3 {
  letter-spacing: 0.2em;
}

legend {
  font-family: 'Dancing Script' !important;
  width: 50%;
}
.carousel-general p.carousel-subcontent {
  font-family: 'Dancing Script';
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
button.slide-button:hover {
  background-color: #f6a604;
  border: 1px solid #f6a604;
  color: #fff;
}
.carousel-item.hk-slide-img img {
  height: 400px;
  filter: brightness(0.99);
  /*position: unset!important;*/
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 20;
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background-color: #b2b2b2;
  background-size: 15px;
}

.btn-outline-secondary {
  border: none;
}

@media (max-width: 768px) {
  .carousel-general .carousel-content h3 {
    letter-spacing: 0.05em;
  }

  .carousel-control-prev {
    left: 10px;
  }
  .carousel-control-next {
    right: 10px;
  }
}
</style>
